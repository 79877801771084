@import url("https://fonts.googleapis.com/css2?family=DM+Mono:wght@300;400;500&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap");

* {
	padding: 0;
	margin: 0;
	border: 0;
	font-display: swap;
	vertical-align: baseline;
	box-sizing: border-box;
	font-family: "Roboto", sans-serif;
	scroll-behavior: smooth;
	outline: none;
	scroll-behavior: smooth;
	scrollbar-color: #a0a0a0 transparent;
	scrollbar-width: thin;
}

*::-webkit-scrollbar {
	width: 7px;
}

*::-webkit-scrollbar-track {
	display: none;
	background: transparent;
}

*::-webkit-scrollbar-thumb {
	background: #a0a0a0;
	border-radius: 5px;
}

*::-webkit-scrollbar-thumb:hover {
	background: #a0a0a0;
}

*:link {
	text-decoration: none;
}

*:active {
	border: none;
	outline: none;
}

body {
	line-height: 1;
	font-size: 16px;
}

ol,
ul {
	list-style: none;
}

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

html {
	height: 100%;
}

#root {
	height: 100%;
	min-height: 100%;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
