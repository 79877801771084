@import '../../general';

.free-demo-button {
    background: $primary !important;
    color: $light !important;
    text-decoration: underline;
    transition: 0.2s ease;
    &:hover {
        cursor: pointer;
        background: none !important;
        color: $primary !important;
    }
}

@media (max-width: 500px) {
  .alone-button {
    padding: 0.5rem 0.6rem !important;
    font-size: 0.9rem !important;
  }
}


.large {
    border: 1px solid $light;
    font-weight: 500;
    font-family: $secondFont;
    font-size: 1rem;
    padding: 0.7rem 1rem;
    @media (min-width: $table) {
      padding: 1rem 2rem;
      font-size: 1.2rem;
    }
    &:hover {
      background: $light !important;
      border: 1px solid $primary;
    }
}

.mobile {
  background: $light !important;
  color: $primary !important;
  &:hover {
    background: $light !important;
  }
}