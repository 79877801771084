@import "../../general";

.background {
	margin-top: 5rem;
	background: $dark;
	.container {
		@include generalContainer;
		max-width: 90%;
		margin-top: 10rem;
		padding: 3rem 0 0 0;
		@media (min-width: $table) {
			padding: 3rem 0;
		}

		.top {
			display: flex;
			justify-content: space-between;
			border-bottom: 1px solid rgb(196, 196, 196);
			flex-direction: column;
			@media (min-width: $table) {
				flex-direction: row;
			}
			.logo {
				width: 9rem;
				display: block;
				margin-bottom: 3rem;
				@media (min-width: $table) {
					margin-bottom: 0;
				}
			}
			.singleMenu {
				h4 {
					color: $light;
					font-weight: 400;
					font-weight: 500;
					text-transform: uppercase;
				}
				ul {
					flex-direction: column;
					margin-top: 1rem;
					@media (min-width: $table) {
						margin-top: 2.5rem;
					}
					li {
						margin: 0.5rem 0;
						.menuLabel {
							color: rgb(196, 196, 196);
							span {
								color: $primary;
							}
						}
					}
				}
			}
		}
	}
	.bottom {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 2rem 0;
		flex-direction: column-reverse;
		@media (min-width: $table) {
			flex-direction: row;
		}

		span {
			color: rgb(196, 196, 196);
		}
		.socialMedia {
			display: flex;
			gap: 0.5rem;
			margin-bottom: 2rem;
			@media (min-width: $table) {
				margin-bottom: 0;
			}
			img {
				width: 2.8rem;
			}
		}
	}
	.simple-container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0px;

		.top {
			border-bottom: none;
			.logo {
				margin-bottom: 0rem;
			}
			@media (max-width: 550px) {
				img {
					width: 80%;
					min-width: 100px;
					max-width: 200px;
				}
			}
		}
		.bottom {
			.socialMedia {
				margin-bottom: 0rem;
				@media (max-width: 550px) {
					img {
						width: 2rem;
					}
				}
			}
			@media (max-width: 550px) {
				a {
					width: 30px !important;
				}
			}
		}
	}
}