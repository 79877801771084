@import "../../../general";

.container {
	padding: 1rem;
	transition: 0.2s;

	@media (max-width: 766px) {
		position: fixed;
		display: block;
		background-color: $light;
		width: 100%;
		z-index: 999;
		top: 0px;
	}

	header {
		display: flex;
		justify-content: space-between;
		padding-bottom: 0.5rem;
		border-bottom: 1px solid rgb(190, 190, 190);
		@media (min-width: 1130px) {
			display: none;
		}
		.left {
			width: 13%;
			.logo {
				max-width: 9rem;
				min-width: 5.5rem;
			}
		}
		.right {
			width: 73%;
			display: flex;
			justify-content: flex-end;
			align-items: center;
			gap: 1rem;
			button {
				padding: 0.5rem 1rem;
				background: none;
				border: 1px solid $primary;
				color: $primary;
				font-family: $secondFont;
				font-size: 0.9rem;
				transition: 0.2s;
				&:hover {
					cursor: pointer;
					background: $primary;
					color: #fff;
				}
			}
			@media (max-width: 456px) {
				button {
					padding: 0.2rem 0.3rem;
					font-size: 0.7rem;
				}
			}
			@media (min-width: 457px) and (max-width: 500px) {
				button {
					padding: 0.5rem 0.6rem;
					font-size: 0.9rem;
				}
			}
			.hamburgerIcon {
				max-width: 2rem;
				&:hover {
					cursor: pointer;
				}
			}
			// .freeDemoBtn {
			// 	background: $primary;
			// 	color: $light;
			// 	text-decoration: underline;
			// 	transition: 0.2s ease;
			// 	&:hover {
			// 		cursor: pointer;
			// 		background: none;
			// 		color: $primary;
			// 	}
			// }
		}
	}
	.itemContiner {
		transition: 0.5s;
		ul {
			li {
				margin: 1rem 0;
				.navItem {
					color: #fff;
					text-transform: capitalize;
				}
				.active {
					border-bottom: 1px solid #fff;
				}
			}
		}
	}
}
.center {
	ul {
		display: flex;
		gap: 2rem;
		padding-left: 15px;
		padding-right: 15px;
		li {
			font-weight: bold;
			color: rgb(59, 59, 59);
			text-transform: capitalize;
			position: relative;
			z-index: 1;
			&:before {
				color: $dark;
				position: absolute;
				left: 0;
				bottom: 0;
				content: "";
				display: block;
				width: 100%;
				height: 2px;
				background-color: $primary;
				z-index: -1;
				transform: scaleX(0);
				transition: 0.2s;
				transform-origin: left;
			}
			&:hover::before {
				transform: scaleX(1);
			}
			&:hover {
				cursor: pointer;
			}
			.active {
				&:before {
					position: absolute;
					left: 0;
					bottom: 0;
					content: "";
					display: block;
					width: 100%;
					height: 2px;
					background-color: $primary;
					z-index: -1;
					transition: 0.2s;
					transform-origin: left;
				}
			}
		}
		.navItem {
			color: $dark;
			font-size: 1rem;
			line-height: 1.4;
		}
	}
}
.menuActived {
	background-color: $primary;
}

.language {
	display: flex;
	align-items: center;
	gap: 0.5rem;
	transition: 0.2s;
	color: $light;
	span {
		text-transform: uppercase;
		font-size: 1rem;
	}
	img {
		width: 1.2rem;
	}
}
.LenguagueSelector {
	// position: absolute;
	// right: 0;
	// top: 0;
	// background-color: #fff;
	// border: 1px solid #aaaaaa;
	transform-origin: top;
	transition: 0.2s;
	color: $light;
	.languageOption {
		display: flex;
		align-items: center;
		gap: 0.5rem;
		margin: 1rem 0;

		span {
			text-transform: uppercase;
			font-size: 1rem;
			width: 1.3rem;
		}
		img {
			width: 1.2rem;
		}
	}
}

.dark-container {
	background: $dark;
	.center {
		ul {
			.navItem {
				color: $light !important;
			}
		}
	}
}