//General Styles
$secondFont: "DM Mono", monospace;

//colors
$primary: #6164f6;
$dark: #262a33;
$gray01: #252a34;
$gray02: #f2f2f0;
$gray03: #bbbbbb;
$light: #fff;

//font sizes
$titleSize01: 1.8rem;

// Media Queries
$mobile: 375px;
$table: 768px;
$desktop: 1024px;
$largeDesktop: 1200px;
$moreThanLargeDesktop: 1300px;
$bigScreen: 1600px;

//mixings
@mixin generalContainer {
  max-width: 95%;
  margin: 0 auto;

  @media (min-width: $largeDesktop) {
    max-width: 980px;
  }
  @media (min-width: $moreThanLargeDesktop) {
    max-width: 1080px;
  }
  @media (min-width: $bigScreen) {
    max-width: 1360px;
  }
}

@mixin error {
  border-color: red !important;
  color: red !important;
  &::placeholder {
    color: red !important;
  }
}

@mixin errorMessageContainer {
  text-align: center;
  margin: 2rem auto 0 auto;
  // width: 95%;
  @media (min-width: $table) {
    // width: 50%;
  }
  p {
    background-color: rgba(255, 0, 0, 0.144);
    padding: 1rem;
    color: red;
  }
}
