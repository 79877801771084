@import "../../../general";

.container {
	display: none;
	border-bottom: 1px solid rgb(190, 190, 190);

	@media (min-width: 1130px) {
		position: fixed;
		display: block;
		background-color: $light;
		width: 100%;
		z-index: 999;
	}

	header {
		@include generalContainer;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 1.5rem 1rem 1rem 1rem;
		.logo {
			width: 6.5rem;
			height: auto;
			object-fit: contain;
		}
		.center {
			ul {
				display: flex;
				gap: 2rem;
				padding-left: 15px;
				padding-right: 15px;
				li {
					font-weight: bold;
					color: rgb(59, 59, 59);
					text-transform: capitalize;
					position: relative;
					z-index: 1;
					&:before {
						color: $dark;
						position: absolute;
						left: 0;
						bottom: 0;
						content: "";
						display: block;
						width: 100%;
						height: 2px;
						background-color: $primary;
						z-index: -1;
						transform: scaleX(0);
						transition: 0.2s;
						transform-origin: left;
					}
					&:hover::before {
						transform: scaleX(1);
					}
					&:hover {
						cursor: pointer;
					}
					.active {
						&:before {
							position: absolute;
							left: 0;
							bottom: 0;
							content: "";
							display: block;
							width: 100%;
							height: 2px;
							background-color: $primary;
							z-index: -1;
							transition: 0.2s;
							transform-origin: left;
						}
					}
				}
				.navItem {
					color: $dark;
					font-size: 1rem;
					line-height: 1.4;
				}
			}
		}
		.right {
			display: flex;
			align-items: center;
			gap: 1rem;
			position: relative;
			button {
				padding: 0.5rem 1rem;
				background: none;
				border: 1px solid $primary;
				color: $primary;
				font-family: $secondFont;
				font-size: 0.9rem;
				transition: 0.2s;
				&:hover {
					cursor: pointer;
					background: $primary;
					color: #fff;
				}
			}

			.language {
				display: flex;
				align-items: center;
				justify-content: space-between;
				gap: 0.5rem;
				border: 1px solid rgb(255, 255, 255);
				padding: 0.5rem 0.5rem;
				transition: 0.2s;
				&:hover {
					cursor: pointer;
					border: 1px solid #aaaaaa;
				}
				span {
					text-transform: uppercase;
					font-size: 0.7rem;
				}
				img {
					width: 1.2rem;
				}
			}
			.LenguagueSelector {
				position: absolute;
				right: 0;
				top: 0;
				background-color: #fff;
				// border: 1px solid #aaaaaa;
				transform-origin: top;
				transition: 0.2s;
				box-shadow: 0 0 2px #686868;
				.languageOption {
					display: flex;
					align-items: center;
					justify-content: space-between;
					gap: 0.5rem;
					border: 1px solid rgb(255, 255, 255);
					padding: 0.5rem 0.5rem;
					&:hover {
						cursor: pointer;
						transform: scale(0.95);
					}
					span {
						text-transform: uppercase;
						font-size: 0.7rem;
					}
					img {
						width: 1.2rem;
					}
				}
			}

			.freeDemoBtn {
				background: $primary;
				color: $light;
				text-decoration: underline;
				transition: 0.2s ease;
				&:hover {
					cursor: pointer;
					background: none;
					color: $primary;
				}
			}
		}

		@media (min-width: $desktop) and (max-width: $largeDesktop) {
			max-width: 95%;
		}

		@media (min-width: $largeDesktop) and (max-width: 1450px) {
			max-width: 97%;
		}
	}
}

.dark-container {
	background: $dark;
	.center {
		ul {
			.navItem {
				color: $light !important;
			}
		}
	}
}
